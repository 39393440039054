@charset "UTF-8";
/* ==========================================================
 * Variables
 *==========================================================*/
/* ==========================================================
 * Mixin
 *==========================================================*/
@media print {
  /** Settings **/
  body {
    font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
    -webkit-print-color-adjust: exact;
  }
  /** Layouts **/
  .header,
  .footer .footer-top, .footer .footer-bottom {
    display: none;
  }
  .wrapper {
    padding-top: 0;
  }
  .contents {
    position: relative;
    margin: 0 auto;
  }
  #news .contents {
    margin: 0 auto 100px;
  }
  .container {
    margin: 0 auto 100px;
    min-width: 1000px;
    width: 1000px;
  }
  #news .container {
    margin: 0 auto;
  }
  .modal-content {
    width: 100%;
  }
  /** GlobalNavigation **/
  .header-logo {
    top: 20px;
  }
  .fixedheader .header-logo {
    top: 10px;
  }
  .list-nav-global {
    display: none !important;
  }
  /** SubNavigation **/
  .contents-layout-01 .contents-sub {
    margin-bottom: 50px;
    width: 100%;
    height: 77.6136px !important;
  }
  .nav-local-01 {
    border-top: 1px dotted #ccc !important;
    border-bottom: 1px dotted #ccc !important;
  }
  .nav-local-01 .inner-wrapper-sticky {
    position: relative !important;
    top: initial !important;
    background-color: initial !important;
    box-shadow: none !important;
  }
  .nav-local-01.is-affixed .inner-wrapper-sticky > ul {
    margin: 20px auto;
  }
  /* ======================================================= */
  /* Components                                              */
  /* ======================================================= */
  /* article-product
  ---------------------------------------------------------- */
  .article-product .list-tab-nav-01 a {
    padding: 20px 40px;
  }
  .article-product .tab table {
    margin: 10px 0 10px 0;
  }
  .article-product .tab table th, .article-product .tab table td {
    padding: 6px;
  }
  .article-product .tab ol, .article-product .tab ul {
    font-size: 85%;
  }
  .article-product .ttl-cmn-01 {
    overflow: hidden;
  }
  .article-product .ttl-cmn-02 {
    overflow: hidden;
  }
  .article-product .box-text-image {
    overflow: hidden;
    margin-bottom: 30px;
  }
  .article-product .box-text-image .fig-cmn-01 {
    position: relative;
    padding-top: 5px;
    z-index: 1;
    background-color: #fff;
    max-width: 450px;
  }
  .article-product .box-text-image .fig-cmn-01.is-right {
    float: right !important;
    padding-left: 20px !important;
  }
  .article-product .box-text-image .fig-cmn-01.is-left {
    float: left !important;
    padding-right: 20px !important;
  }
  .article-product .box-text-image .fig-cmn-01 .txt-caption {
    margin-bottom: 5px;
    text-align: center;
  }
  .article-product .box-text-image .detail p {
    line-height: 2;
  }
  .article-product .sec-products-01 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .article-product .box-image {
    margin-bottom: 40px;
    text-align: center;
  }
  .article-product .box-image img {
    max-width: 100%;
    margin: 0 auto;
  }
  .article-product .box-image.full img {
    width: 100%;
    max-width: none;
  }
  .article-product .list-cards {
    overflow: hidden;
  }
  .article-product .list-cards li {
    float: left;
    width: 25%;
    margin-top: 20px;
    padding: 0 5px;
  }
  .article-product .list-cards li:not(:last-child) {
    margin-bottom: 15px;
  }
  .article-product .list-cards li:nth-child(4n+1) {
    clear: left;
  }
  .article-product .list-cards .image {
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
  }
  .article-product .list-cards .image img {
    width: 100%;
  }
  .article-product .list-cards .title {
    font-size: 16px;
    font-size: 1.1em;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .article-product .list-cards .text {
    line-height: 2;
  }
  /* article-product
  ---------------------------------------------------------- */
  /* article-quality
---------------------------------------------------------- */
  .article-quality p {
    color: #000;
  }
  .article-quality p:not(:last-child) {
    margin-bottom: 20px;
  }
  .article-quality .main-image img {
    max-width: 100%;
  }
  .article-quality .box-text-image {
    overflow: hidden;
  }
  .article-quality .box-text-image:not(:last-child) {
    margin-bottom: 25px;
  }
  .article-quality .box-text-image .image {
    float: right;
  }
  .article-quality .box-text-image .detail {
    padding-right: 20px;
    overflow: hidden;
  }
  .article-quality .list-number-01 {
    margin-bottom: 30px;
    color: #000;
    line-height: 2;
  }
  .article-quality .list-number-01 li:before {
    color: #000;
  }
  /* ======================================================= */
  /* Pages                                                   */
  /* ======================================================= */
  /* corporate
  ---------------------------------------------------------- */
  #g01 .list-card-links.type-3-col li {
    width: 332.3px;
  }
  #g01 .list-card-links.type-3-col .box-card {
    width: 332.3px;
  }
  #g01 .list-card-links.type-3-col .box-card img {
    width: 99%;
  }
  /* ----------------------------------------------------------
    営業拠点
---------------------------------------------------------- */
  .mod-sales-base .contact .tel-sp {
    display: none;
  }
}
